import React, { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import './Gallery.css'
import { createFileTree, FileTree } from '../../data/helpers'
import images from '../../data/images'
import FolderIcon from '../../images/icons/folderIcon'
import { CloseIcon } from 'yet-another-react-lightbox'

interface FileNode {
    path: string;
}

const isFileNode = (node: any): node is FileNode => {
    return node && typeof node === 'object' && 'path' in node;
};

export default function Gallery() {
    const initialFileTree = createFileTree(images);
    const [folderStack, setFolderStack] = useState<FileTree[]>([initialFileTree]);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const handleFolderClick = (folder: FileTree) => {
        setFolderStack(prevStack => [...prevStack, folder]);
    };

    const handleBackClick = () => {
        setFolderStack(prevStack => {
            if (prevStack.length > 1) {
                return prevStack.slice(0, -1);
            }
            return prevStack;
        });
    };

    const handleImageClick = (imagePath: string) => {
        setSelectedImage(imagePath);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const currentFolder = folderStack[folderStack.length - 1];

    const findNextImage = (currentImage: string | null): string | null => {
        if (!currentImage || !currentFolder) return null;

        const currentFolderKeys = Object.keys(currentFolder);
        let found = false;

        for (let i = 0; i < currentFolderKeys.length; i++) {
            const key = currentFolderKeys[i];
            const node = currentFolder[key];
            if (isFileNode(node)) {
                if (found) {
                    return node.path;
                }
                if (node.path === currentImage) {
                    found = true;
                }
            }
        }

        return null;
    };

    const findPreviousImage = (currentImage: string | null): string | null => {
        if (!currentImage || !currentFolder) return null;

        const currentFolderKeys = Object.keys(currentFolder);
        let found = false;

        for (let i = currentFolderKeys.length - 1; i >= 0; i--) {
            const key = currentFolderKeys[i];
            const node = currentFolder[key];
            if (isFileNode(node)) {
                if (found) {
                    return node.path;
                }
                if (node.path === currentImage) {
                    found = true;
                }
            }
        }

        return null;
    };

    const handleNextClick = () => {
        const nextImage = findNextImage(selectedImage);
        setSelectedImage(nextImage);
    };

    const handlePreviousClick = () => {
        const previousImage = findPreviousImage(selectedImage);
        setSelectedImage(previousImage);
    };

    return (
      <Box className="box-padding">
          {folderStack.length > 1 &&
            <Button className="button-margin-bottom" variant="contained" onClick={handleBackClick}>Zurück</Button>}

          <Box className="box-display-flex" justifyContent={{ xs: 'center', md: 'flex-start' }}>
              {Object.keys(currentFolder).map((key) => {
                  const node = currentFolder[key]
                  return (
                    <Box key={key} className="box-height"
                         onClick={() => isFileNode(node) ? handleImageClick(node.path) : handleFolderClick(node as FileTree)}>
                        {isFileNode(node) ? (
                          <Box className="box-cursor-pointer">
                              <img loading="lazy" src={node.path} alt={key} style={{ height: '200px' }}/>
                          </Box>
                        ) : (
                          <Box className="box-display-flex-column">
                              <FolderIcon/>
                              <Box textAlign="center" minHeight='40px' width="80%">{key}</Box>
                          </Box>
                        )}
                    </Box>
                  )
              })}
          </Box>
          {selectedImage && (
            <Box className="fullscreen-overlay">
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                      position: 'absolute',
                      right: { xs: 16, md: 50 }, top: 16,
                      color: 'white',
                      zIndex: 1100 // Ensure the close button is above the overlay
                  }}
                >
                    <CloseIcon/>
                </IconButton>
                <Box className="image-container">
                    <IconButton
                      aria-label="previous"
                      onClick={handlePreviousClick}
                      sx={{
                          position: 'absolute',
                          left: 16,
                          top: '50%',
                          color: 'white',
                          zIndex: 1100 // Ensure the previous button is above the overlay
                      }}
                    >
                        &#9664;
                    </IconButton>
                    <img loading="lazy" src={selectedImage} alt="Selected" className="fullscreen-image"/>
                    <IconButton
                      aria-label="next"
                      onClick={handleNextClick}
                      sx={{
                          position: 'absolute',
                          right: 16,
                          top: '50%',
                          color: 'white',
                          zIndex: 1100 // Ensure the next button is above the overlay
                      }}
                    >
                        &#9654;
                    </IconButton>
                </Box>
            </Box>
          )}
      </Box>
    );
}
